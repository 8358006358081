import { lazy, Suspense, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import { ModalProvider } from './context/ModalContext';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { LoadingSpinner } from './components/LoadingSpinner';
import { CookieConsentProvider } from './context/CookieConsentContext';
import { TrackingErrorBoundary } from './components/TrackingErrorBoundary';
import { TrackingScripts } from './components/TrackingScripts';
import { CookieConsent } from './components/CookieConsent';
import { AIChatWidget } from './components/ChatWidget';
import { ScrollRestoration } from './components/ScrollRestoration';

// Lazy load all components
const LazyHome = lazy(() => import('./pages/Home').then(m => ({ default: m.Home as React.ComponentType })));
const LazyFreeAnalysis = lazy(() => import('./pages/FreeAnalysis').then(m => ({ default: m.FreeAnalysis as React.ComponentType })));
const LazyWebsiteAuditTemplate = lazy(() => import('./pages/tools/WebsiteAuditTemplate').then(m => ({ default: m.WebsiteAuditTemplate as React.ComponentType })));
const LazyPartners = lazy(() => import('./pages/Partners').then(m => ({ default: m.Partners as React.ComponentType })));
const LazyAffiliates = lazy(() => import('./pages/Affiliates').then(m => ({ default: m.Affiliates as React.ComponentType })));
const LazyFreeTools = lazy(() => import('./pages/FreeTools').then(m => ({ default: m.FreeTools as React.ComponentType })));
const LazyTechnicalGuides = lazy(() => import('./pages/TechnicalGuides').then(m => ({ default: m.TechnicalGuides })));
const LazyTransformationRoadmaps = lazy(() => import('./pages/TransformationRoadmaps').then(m => ({ default: m.TransformationRoadmaps })));

// Lazy load service pages
const LazyWebDevelopment = lazy(() => import('./pages/services/WebDevelopment').then(m => ({ default: m.WebDevelopment })));
const LazyEcommerceSolutions = lazy(() => import('./pages/services/EcommerceSolutions').then(m => ({ default: m.EcommerceSolutions })));
const LazyAIIntegration = lazy(() => import('./pages/services/AIIntegration').then(m => ({ default: m.AIIntegration })));
const LazyBrowserExtensions = lazy(() => import('./pages/services/BrowserExtensions').then(m => ({ default: m.BrowserExtensions })));
const LazyAutomationScripts = lazy(() => import('./pages/services/AutomationScripts').then(m => ({ default: m.AutomationScripts })));
const LazySEOOptimization = lazy(() => import('./pages/services/SEOOptimization').then(m => ({ default: m.SEOOptimization })));
const LazyAnalyticsTracking = lazy(() => import('./pages/services/AnalyticsTracking').then(m => ({ default: m.AnalyticsTracking })));
const LazyCustomSolutions = lazy(() => import('./pages/services/CustomSolutions').then(m => ({ default: m.CustomSolutions })));
const LazyPWADevelopment = lazy(() => import('./pages/services/PWADevelopment').then(m => ({ default: m.PWADevelopment })));

// Lazy load industry pages
const LazyRetail = lazy(() => import('./pages/industries/Retail').then(m => ({ default: m.Retail })));
const LazyHealthcare = lazy(() => import('./pages/industries/Healthcare').then(m => ({ default: m.Healthcare })));
const LazyFinance = lazy(() => import('./pages/industries/Finance').then(m => ({ default: m.Finance })));
const LazyEducation = lazy(() => import('./pages/industries/Education').then(m => ({ default: m.Education })));
const LazyManufacturing = lazy(() => import('./pages/industries/Manufacturing').then(m => ({ default: m.Manufacturing })));
const LazyRealEstate = lazy(() => import('./pages/industries/RealEstate').then(m => ({ default: m.RealEstate })));
const LazyTravel = lazy(() => import('./pages/industries/Travel').then(m => ({ default: m.Travel })));
const LazyProfessionals = lazy(() => import('./pages/industries/Professionals').then(m => ({ default: m.Professionals })));
const LazySMB = lazy(() => import('./pages/industries/SMB').then(m => ({ default: m.SMB })));

// Lazy load portfolio pages
const LazyEcommerceBIDashboard = lazy(() => import('./pages/portfolio/EcommerceBIDashboard').then(m => ({ default: m.EcommerceBIDashboard })));
const LazyDentalClinic = lazy(() => import('./pages/portfolio/DentalClinic').then(m => ({ default: m.DentalClinic })));
const LazyLawFirmPortal = lazy(() => import('./pages/portfolio/LawFirmPortal').then(m => ({ default: m.LawFirmPortal })));
const LazyHotelBooking = lazy(() => import('./pages/portfolio/HotelBooking').then(m => ({ default: m.HotelBooking })));
const LazyRestaurantOrdering = lazy(() => import('./pages/portfolio/RestaurantOrdering').then(m => ({ default: m.RestaurantOrdering })));
const LazyRadioStation = lazy(() => import('./pages/portfolio/RadioStation').then(m => ({ default: m.RadioStation })));
const LazyCityHallPortal = lazy(() => import('./pages/portfolio/CityHallPortal').then(m => ({ default: m.CityHallPortal })));
const LazyCreditCompany = lazy(() => import('./pages/portfolio/CreditCompany').then(m => ({ default: m.CreditCompany })));
const LazyLogisticsManagement = lazy(() => import('./pages/portfolio/LogisticsManagement').then(m => ({ default: m.LogisticsManagement })));
const LazyRealEstatePlatform = lazy(() => import('./pages/portfolio/RealEstatePlatform').then(m => ({ default: m.RealEstatePlatform })));
const LazyTravelAgency = lazy(() => import('./pages/portfolio/TravelAgency').then(m => ({ default: m.TravelAgency })));
const LazyOptometristStore = lazy(() => import('./pages/portfolio/OptometristStore').then(m => ({ default: m.OptometristStore })));
const LazyFashionStore = lazy(() => import('./pages/portfolio/FashionStore').then(m => ({ default: m.FashionStore })));
const LazyShopifyOptimization = lazy(() => import('./pages/portfolio/ShopifyOptimization').then(m => ({ default: m.ShopifyOptimization })));
const LazyShopifyPerformance = lazy(() => import('./pages/portfolio/ShopifyPerformance').then(m => ({ default: m.ShopifyPerformance })));
const LazyServerMigration = lazy(() => import('./pages/portfolio/ServerMigration').then(m => ({ default: m.ServerMigration })));
const LazyAIChatbotFramework = lazy(() => import('./pages/portfolio/AIChatbotFramework').then(m => ({ default: m.AIChatbotFramework })));
const LazyBrowserExtensionFramework = lazy(() => import('./pages/portfolio/BrowserExtensionFramework').then(m => ({ default: m.BrowserExtensionFramework })));
const LazyReactPersonalWebsite = lazy(() => import('./pages/portfolio/ReactPersonalWebsite').then(m => ({ default: m.ReactPersonalWebsite })));
const LazyWordPressSecurityPlugin = lazy(() => import('./pages/portfolio/WordPressSecurityPlugin').then(m => ({ default: m.WordPressSecurityPlugin })));
const LazyShopifyAnalyticsApp = lazy(() => import('./pages/portfolio/ShopifyAnalyticsApp').then(m => ({ default: m.ShopifyAnalyticsApp })));
const LazyWordPressPerformance = lazy(() => import('./pages/portfolio/WordPressPerformance').then(m => ({ default: m.WordPressPerformance })));
const LazyServerOptimization = lazy(() => import('./pages/portfolio/ServerOptimization').then(m => ({ default: m.ServerOptimization })));

// Lazy load legal pages
const LazyTerms = lazy(() => import('./pages/legal/Terms').then(m => ({ default: m.Terms })));
const LazyPrivacy = lazy(() => import('./pages/legal/Privacy').then(m => ({ default: m.Privacy })));
const LazyCookies = lazy(() => import('./pages/legal/Cookies').then(m => ({ default: m.Cookies })));
const LazyGDPR = lazy(() => import('./pages/legal/GDPR').then(m => ({ default: m.GDPR })));
const LazySecurity = lazy(() => import('./pages/legal/Security').then(m => ({ default: m.Security })));
const LazyAccessibility = lazy(() => import('./pages/legal/Accessibility').then(m => ({ default: m.Accessibility })));
const LazySLA = lazy(() => import('./pages/legal/SLA').then(m => ({ default: m.SLA })));
const LazyImpressum = lazy(() => import('./pages/legal/Impressum').then(m => ({ default: m.Impressum })));

// Add new routes
const LazyWebsiteMonitoring = lazy(() => import('./pages/monitoring/WebsiteMonitoring').then(m => ({ default: m.WebsiteMonitoring })));
const LazyAIRecommendations = lazy(() => import('./pages/ai-recommendations/AIRecommendations').then(m => ({ default: m.AIRecommendations })));
const LazyDigitalCommerceTransformation = lazy(() => import('./pages/roadmaps/DigitalCommerceTransformation').then(m => ({ default: m.DigitalCommerceTransformation })));
const LazyCloudMigrationStrategy = lazy(() => import('./pages/roadmaps/CloudMigrationStrategy').then(m => ({ default: m.CloudMigrationStrategy })));
const LazyDigitalMarketingEvolution = lazy(() => import('./pages/roadmaps/DigitalMarketingEvolution').then(m => ({ default: m.DigitalMarketingEvolution })));
const LazyModernFrontendArchitecture = lazy(() => import('./pages/guides/ModernFrontendArchitecture').then(m => ({ default: m.ModernFrontendArchitecture })));
const LazyWordPressSecurity = lazy(() => import('./pages/guides/WordPressSecurity').then(m => ({ default: m.WordPressSecurity })));

const LazyPortfolioDetail = lazy(() => import('./components/PortfolioDetail').then(m => ({ default: m.PortfolioDetail })));

function App() {
  const location = useLocation();

  useEffect(() => {
    // Check for scroll target from navigation
    if (location.state?.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
      window.history.replaceState({}, document.title);
    } else if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <CookieConsentProvider>
      <TrackingErrorBoundary>
        <TrackingScripts />
      </TrackingErrorBoundary>
      <ThemeProvider>
        <ModalProvider>
          <div className="min-h-screen bg-gray-50 flex flex-col">
            <Header />
            <main className="flex-grow">
              <Suspense fallback={<LoadingSpinner />}>
                <Routes>
                  {/* Main routes */}
                  <Route path="/" element={<LazyHome />} />
                  <Route path="/free-analysis" element={<LazyFreeAnalysis />} />
                  <Route path="/partners" element={<LazyPartners />} />
                  <Route path="/affiliates" element={<LazyAffiliates />} />
                  <Route path="/free-tools" element={<LazyFreeTools />} />
                  <Route path="/technical-guides" element={<LazyTechnicalGuides />} />
                  <Route path="/transformation-roadmaps" element={<LazyTransformationRoadmaps />} />
                  <Route path="/roadmaps/digital-commerce" element={<LazyDigitalCommerceTransformation />} />
                  <Route path="/roadmaps/cloud-migration" element={<LazyCloudMigrationStrategy />} />
                  <Route path="/roadmaps/digital-marketing" element={<LazyDigitalMarketingEvolution />} />

                  {/* Tools Routes */}
                  <Route path="/tools/website-audit-template" element={<LazyWebsiteAuditTemplate />} />

                  {/* Service Routes */}
                  <Route path="/services/web-development" element={<LazyWebDevelopment />} />
                  <Route path="/services/ecommerce-solutions" element={<LazyEcommerceSolutions />} />
                  <Route path="/services/ai-integration" element={<LazyAIIntegration />} />
                  <Route path="/services/browser-extensions" element={<LazyBrowserExtensions />} />
                  <Route path="/services/automation-scripts" element={<LazyAutomationScripts />} />
                  <Route path="/services/seo-optimization" element={<LazySEOOptimization />} />
                  <Route path="/services/analytics-tracking" element={<LazyAnalyticsTracking />} />
                  <Route path="/services/custom-solutions" element={<LazyCustomSolutions />} />
                  <Route path="/services/pwa" element={<LazyPWADevelopment />} />

                  {/* Industry Routes */}
                  <Route path="/industries/retail" element={<LazyRetail />} />
                  <Route path="/industries/healthcare" element={<LazyHealthcare />} />
                  <Route path="/industries/finance" element={<LazyFinance />} />
                  <Route path="/industries/education" element={<LazyEducation />} />
                  <Route path="/industries/manufacturing" element={<LazyManufacturing />} />
                  <Route path="/industries/real-estate" element={<LazyRealEstate />} />
                  <Route path="/industries/travel" element={<LazyTravel />} />
                  <Route path="/industries/professionals" element={<LazyProfessionals />} />
                  <Route path="/industries/smb" element={<LazySMB />} />

                  {/* Portfolio Routes */}
                  <Route path="/portfolio/ecommerce-bi-dashboard" element={<LazyEcommerceBIDashboard />} />
                  <Route path="/portfolio/dental-clinic" element={<LazyDentalClinic />} />
                  <Route path="/portfolio/law-firm-portal" element={<LazyLawFirmPortal />} />
                  <Route path="/portfolio/hotel-booking" element={<LazyHotelBooking />} />
                  <Route path="/portfolio/restaurant-ordering" element={<LazyRestaurantOrdering />} />
                  <Route path="/portfolio/radio-station" element={<LazyRadioStation />} />
                  <Route path="/portfolio/city-hall-portal" element={<LazyCityHallPortal />} />
                  <Route path="/portfolio/credit-company" element={<LazyCreditCompany />} />
                  <Route path="/portfolio/logistics-management" element={<LazyLogisticsManagement />} />
                  <Route path="/portfolio/real-estate-platform" element={<LazyRealEstatePlatform />} />
                  <Route path="/portfolio/travel-agency" element={<LazyTravelAgency />} />
                  <Route path="/portfolio/optometrist-store" element={<LazyOptometristStore />} />
                  <Route path="/portfolio/fashion-store" element={<LazyFashionStore />} />
                  <Route path="/portfolio/shopify-optimization" element={<LazyShopifyOptimization />} />
                  <Route path="/portfolio/shopify-performance" element={<LazyShopifyPerformance />} />
                  <Route path="/portfolio/server-migration" element={<LazyServerMigration />} />
                  <Route path="/portfolio/ai-chatbot-framework" element={<LazyAIChatbotFramework />} />
                  <Route path="/portfolio/browser-extension-framework" element={<LazyBrowserExtensionFramework />} />
                  <Route path="/portfolio/react-personal-website" element={<LazyReactPersonalWebsite />} />
                  <Route path="/portfolio/wordpress-security-plugin" element={<LazyWordPressSecurityPlugin />} />
                  <Route path="/portfolio/shopify-analytics-app" element={<LazyShopifyAnalyticsApp />} />
                  <Route path="/portfolio/wordpress-performance" element={<LazyWordPressPerformance />} />
                  <Route path="/portfolio/server-optimization" element={<LazyServerOptimization />} />

                  {/* Legal Routes */}
                  <Route path="/legal/terms" element={<LazyTerms />} />
                  <Route path="/legal/privacy" element={<LazyPrivacy />} />
                  <Route path="/legal/cookies" element={<LazyCookies />} />
                  <Route path="/legal/gdpr" element={<LazyGDPR />} />
                  <Route path="/legal/security" element={<LazySecurity />} />
                  <Route path="/legal/accessibility" element={<LazyAccessibility />} />
                  <Route path="/legal/sla" element={<LazySLA />} />
                  <Route path="/legal/impressum" element={<LazyImpressum />} />

                  {/* Monitoring and AI Recommendations */}
                  <Route path="/monitoring" element={<LazyWebsiteMonitoring />} />
                  <Route path="/ai-recommendations" element={<LazyAIRecommendations />} />

                  {/* Guides */}
                  <Route path="/guides/modern-frontend-architecture" element={<LazyModernFrontendArchitecture />} />
                  <Route path="/guides/wordpress-security" element={<LazyWordPressSecurity />} />

                  {/* Portfolio routes with fallback */}
                  <Route path="/portfolio/:projectId" element={<LazyPortfolioDetail />} />
                  <Route path="/portfolio" element={<Navigate to="/#portfolio" replace />} />

                  {/* Other routes with hash fallbacks */}
                  <Route path="/services" element={<Navigate to="/#services" replace />} />
                  
                  {/* Additional Portfolio routes */}
                  <Route path="/portfolio/ecommerce-bi-dashboard" element={<LazyEcommerceBIDashboard />} />
                  <Route path="/portfolio/dental-clinic" element={<LazyDentalClinic />} />
                  <Route path="/portfolio/law-firm-portal" element={<LazyLawFirmPortal />} />
                  <Route path="/portfolio/hotel-booking" element={<LazyHotelBooking />} />
                  <Route path="/portfolio/restaurant-ordering" element={<LazyRestaurantOrdering />} />
                  <Route path="/portfolio/radio-station" element={<LazyRadioStation />} />
                  <Route path="/portfolio/city-hall-portal" element={<LazyCityHallPortal />} />
                  <Route path="/portfolio/credit-company" element={<LazyCreditCompany />} />
                  <Route path="/portfolio/logistics-management" element={<LazyLogisticsManagement />} />
                  <Route path="/portfolio/real-estate-platform" element={<LazyRealEstatePlatform />} />
                  <Route path="/portfolio/travel-agency" element={<LazyTravelAgency />} />
                  <Route path="/portfolio/optometrist-store" element={<LazyOptometristStore />} />
                  <Route path="/portfolio/fashion-store" element={<LazyFashionStore />} />
                  <Route path="/portfolio/shopify-optimization" element={<LazyShopifyOptimization />} />
                  <Route path="/portfolio/shopify-performance" element={<LazyShopifyPerformance />} />
                  <Route path="/portfolio/server-migration" element={<LazyServerMigration />} />
                  <Route path="/portfolio/ai-chatbot-framework" element={<LazyAIChatbotFramework />} />
                  <Route path="/portfolio/browser-extension-framework" element={<LazyBrowserExtensionFramework />} />
                  <Route path="/portfolio/react-personal-website" element={<LazyReactPersonalWebsite />} />
                  <Route path="/portfolio/wordpress-security-plugin" element={<LazyWordPressSecurityPlugin />} />
                  <Route path="/portfolio/shopify-analytics-app" element={<LazyShopifyAnalyticsApp />} />
                  <Route path="/portfolio/wordpress-performance" element={<LazyWordPressPerformance />} />
                  <Route path="/portfolio/server-optimization" element={<LazyServerOptimization />} />
                  <Route path="/testimonials" element={<Navigate to="/#testimonials" replace />} />
                  <Route path="/contact" element={<Navigate to="/#contact" replace />} />

                  {/* Catch all route */}
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </Suspense>
            </main>
            <Footer />
            <CookieConsent />
            <AIChatWidget />
            <ScrollRestoration />
          </div>
        </ModalProvider>
      </ThemeProvider>
    </CookieConsentProvider>
  );
}

export default App;