interface TrackingConfig {
  facebook: {
    pixelId: string;
  };
  linkedin: {
    pixelId: string;
  };
  twitter: {
    pixelId: string;
  };
  google: {
    analyticsId: string;
    tagManagerId: string;
  };
}

export const trackingConfig: TrackingConfig = {
  facebook: {
    pixelId: '1974222829730857'
  },
  linkedin: {
    pixelId: '7743705'
  },
  twitter: {
    pixelId: 'YOUR_TWITTER_PIXEL_ID'
  },
  google: {
    analyticsId: 'G-ZKQX4G5Q1F',
    tagManagerId: 'GTM-KF9W2DSZ'
  }
}; 
