export type Theme = {
  primary: string;
  secondary: string;
  accent: string;
  background: string;
  text: string;
};

export const defaultTheme: Theme = {
  primary: '#2563eb',    // Blue
  secondary: '#1e293b',  // Slate
  accent: '#f97316',     // Orange
  background: '#ffffff', // White
  text: '#0f172a',      // Dark slate
};