import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useCookieConsent } from '../context/CookieConsentContext';

export function CookieConsentHandler() {
  const location = useLocation();
  const { hasConsented } = useCookieConsent();

  useEffect(() => {
    if (!hasConsented) {
      localStorage.removeItem('cookieConsent');
      sessionStorage.removeItem('hasConsented');
    }
  }, [location.pathname, hasConsented]);

  return null;
} 