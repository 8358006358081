import React, { createContext, useContext, useState } from 'react';
import { useLanguage } from './LanguageContext';
import { X } from 'lucide-react';
import confetti from 'canvas-confetti';
import { supabase } from '../lib/supabaseClient';
import * as ContactSubmission from '../types/ContactSubmission';    

type ModalTranslationKey = 
  | 'modal.title'
  | 'modal.content'
  | 'modal.form.name'
  | 'modal.form.email'
  | 'modal.form.phone'
  | 'modal.form.company'
  | 'modal.form.message'
  | 'modal.form.submit'
  | 'modal.form.cancel'
  | 'modal.form.projectType'
  | 'modal.form.required'
  | 'modal.form.error'
  | 'modal.form.submitting'
  | 'modal.form.success'
  | 'modal.form.url';

interface ModalTranslations {
  [key: string]: {
    [K in ModalTranslationKey]: string;
  };
}

const modalTranslations: ModalTranslations = {
  en: {
    'modal.title': 'Schedule a Free Consultation',
    'modal.content': "Let's discuss your project and find the best solutions for your business needs.",
    'modal.form.name': 'Full Name',
    'modal.form.email': 'Email Address',
    'modal.form.url': 'Website URL',
    'modal.form.phone': 'Phone Number',
    'modal.form.company': 'Company Name',
    'modal.form.projectType': 'Project Type',
    'modal.form.message': 'Message',
    'modal.form.submit': 'Submit',
    'modal.form.cancel': 'Cancel',
    'modal.form.required': 'Required',
    'modal.form.error': 'An error occurred during submission.',
    'modal.form.submitting': 'Submitting...',
    'modal.form.success': 'Submission successful!'
  },
  de: {
    'modal.title': 'Kostenlose Beratung vereinbaren',
    'modal.content': 'Lassen Sie uns Ihr Projekt besprechen und die besten Lösungen für Ihre geschäftlichen Anforderungen finden.',
    'modal.form.name': 'Vollständiger Name',
    'modal.form.email': 'E-Mail-Adresse',
    'modal.form.url': 'Website-URL',
    'modal.form.phone': 'Telefonnummer',
    'modal.form.company': 'Firmenname',
    'modal.form.projectType': 'Projekttyp',
    'modal.form.message': 'Nachricht',
    'modal.form.submit': 'Absenden',
    'modal.form.cancel': 'Abbrechen',
    'modal.form.required': 'Erforderlich',
    'modal.form.error': 'Es ist ein Fehler beim Absenden aufgetreten.',
    'modal.form.submitting': 'Wird gesendet...',
    'modal.form.success': 'Absendung erfolgreich!'
  },
  el: {
    'modal.title': 'Προγραμματίστε Δωρεάν Συμβουλευτική',
    'modal.content': 'Ας συζητήσουμε το έργο σας και ας βρούμε τις καλύτερες λύσεις για τις επιχειρηματικές σας ανάγκες.',
    'modal.form.name': 'Ονοματεπώνυμο',
    'modal.form.email': 'Διεύθυνση Email',
    'modal.form.url': 'URL Ιστοσελίδας',
    'modal.form.phone': 'Τηλέφωνο',
    'modal.form.company': 'Όνομα Εταιρείας',
    'modal.form.projectType': 'Τύπος Έργου',
    'modal.form.message': 'Μήνυμα',
    'modal.form.submit': 'Υποβολή',
    'modal.form.cancel': 'Ακύρωση',
    'modal.form.required': 'Απαιτείται',
    'modal.form.error': 'Παρουσιάστηκε ένα σφάλμα κατά την υποβολή.',
    'modal.form.submitting': 'Παραδίδεται...',
    'modal.form.success': 'Η υποβολή ολοκληρώθηκε επιτυχώς!'
  },
  tr: {
    'modal.title': 'Ücretsiz Danışmanlık Planlayın',
    'modal.content': 'Projenizi tartışalım ve işletme ihtiyaçlarınız için en iyi çözümleri bulalım.',
    'modal.form.name': 'Ad Soyad',
    'modal.form.email': 'E-posta Adresi',
    'modal.form.url': 'Website URL',
    'modal.form.phone': 'Telefon Numarası',
    'modal.form.company': 'Şirket Adı',
    'modal.form.projectType': 'Proje Türü',
    'modal.form.message': 'Mesaj',
    'modal.form.submit': 'Gönder',
    'modal.form.cancel': 'İptal',
    'modal.form.required': 'Gerekli',
    'modal.form.error': 'Gönderirken bir hata oluştu.',
    'modal.form.submitting': 'Gönderiliyor...',
    'modal.form.success': 'Gönderim başarılı!'
  }
};

const projectTypes = {
  en: [
    { value: 'web_development', label: 'Web Development' },
    { value: 'ecommerce', label: 'E-commerce Development' },
    { value: 'pwa_enhancement', label: 'PWA Website Enhancement' },
    { value: 'ai_integration', label: 'AI Integration' },
    { value: 'browser_extension', label: 'Browser Extension' },
    { value: 'automation', label: 'Process Automation' },
    { value: 'seo', label: 'SEO Optimization' },
    { value: 'analytics', label: 'Analytics Setup' },
    { value: 'partnership', label: 'Partnership Request' },
    { value: 'affiliate', label: 'Affiliate Request' },
    { value: 'it_support', label: 'General IT and Web Support' },
    { value: 'other', label: 'Other' }
  ],
  // Add translations for other languages...
};

interface ModalProps {
  title: string;
  content: string;
  titleKey: string;
  contentKey: string;
  includeUrlField?: boolean;
}

interface ModalContent {
  title: string;
  content: React.ReactNode;
}

interface ModalContextType {
  openModal: (props: ModalProps) => void;
  closeModal: () => void;
  isOpen: boolean;
  modalContent: ModalContent | null;
}

const ModalContext = createContext<{
  openModal: (props: ModalProps) => void;
  closeModal: () => void;
  isOpen: boolean;
  modalProps: ModalProps | null;
}>({
  openModal: () => {},
  closeModal: () => {},
  isOpen: false,
  modalProps: null,
});

interface FormData extends Omit<ContactSubmission.ContactSubmission, 'id'> {
  // all fields are already defined in ContactSubmission
}

export function ModalProvider({ children }: { children: React.ReactNode }) {
  const { language } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState<ModalProps | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const getTranslation = (key: ModalTranslationKey): string => {
    return modalTranslations[language as keyof typeof modalTranslations]?.[key] || 
           modalTranslations.en[key];
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitSuccess(false);

    const form = e.currentTarget;
    const formElements = form.elements as typeof form.elements & {
      name: HTMLInputElement;
      email: HTMLInputElement;
      url: HTMLInputElement;
      phone: HTMLInputElement;
      company: HTMLInputElement;
      projectType: HTMLSelectElement;
      message: HTMLTextAreaElement;
    };

    try {
      const formData: Omit<ContactSubmission.ContactSubmission, 'id'> = {
        name: formElements.name.value.trim(),
        email: formElements.email.value.trim(),
        url: formElements.url?.value.trim() || undefined,
        phone: formElements.phone.value.trim() || undefined,
        company: formElements.company.value.trim() || undefined,
        project_type: formElements.projectType.value,
        message: formElements.message.value.trim(),
        submitted_at: new Date().toISOString(),
        language,
        source_page: window.location.pathname,
        status: 'new'
      };

      // 1. Store in Supabase
      const { error: supabaseError } = await supabase
        .from('contact_submissions')
        .insert([formData]);

      if (supabaseError) throw supabaseError;

      // Success handling
      setSubmitSuccess(true);
      form.reset();
      
      // Show confetti
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
        colors: ['#2563eb', '#3b82f6', '#60a5fa']
      });

      // Close modal after delay
      setTimeout(() => {
        setIsOpen(false);
        setModalProps(null);
        setSubmitSuccess(false);
      }, 2000);

    } catch (error) {
      console.error('Submission error:', error);
      alert(getTranslation('modal.form.error'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const openModal = (props: ModalProps) => {
    const translatedProps = {
      ...props,
      title: props.titleKey ? getTranslation(props.titleKey as ModalTranslationKey) : props.title,
      content: props.contentKey ? getTranslation(props.contentKey as ModalTranslationKey) : props.content
    };
    setModalProps(translatedProps);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalProps(null);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal, isOpen, modalProps }}>
      {children}
      {isOpen && modalProps && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-screen items-center justify-center p-4">
            <div 
              className="fixed inset-0 bg-black/50 transition-opacity"
              onClick={closeModal}
            />
            
            <div className="bg-white rounded-lg shadow-xl max-w-lg w-full max-h-[90vh] overflow-y-auto relative z-10">
              <div className="p-6">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-2xl font-bold text-gray-900">
                    {modalProps.title}
                  </h2>
                  <button 
                    onClick={closeModal}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <X className="w-6 h-6" />
                  </button>
                </div>
                
                <div className="p-6">
                  <p className="text-gray-600 mb-6">
                    {modalProps.content}
                  </p>
                  {/* Form content */}
                  <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        {getTranslation('modal.form.name')} *
                      </label>
                      <input
                        name="name"
                        type="text"
                        required
                        className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        {getTranslation('modal.form.email')} *
                      </label>
                      <input
                        name="email"
                        type="email"
                        required
                        className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        {getTranslation('modal.form.phone')}
                      </label>
                      <input
                        name="phone"
                        type="tel"
                        className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        {getTranslation('modal.form.company')}
                      </label>
                      <input
                        name="company"
                        type="text"
                        className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        {getTranslation('modal.form.projectType')} *
                      </label>
                      <select
                        name="projectType"
                        required
                        className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      >
                        <option value="">{`Select ${getTranslation('modal.form.projectType').toLowerCase()}`}</option>
                        {projectTypes.en.map(type => (
                          <option key={type.value} value={type.value}>
                            {type.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        {getTranslation('modal.form.message')} *
                      </label>
                      <textarea
                        name="message"
                        required
                        rows={4}
                        className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      />
                    </div>
                    <div className="flex justify-end gap-4">
                      <button
                        type="button"
                        onClick={() => setIsOpen(false)}
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
                        disabled={isSubmitting}
                      >
                        {getTranslation('modal.form.cancel')}
                      </button>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`
                          px-4 py-2 text-sm font-medium text-white rounded-md
                          transition-colors duration-200
                          ${isSubmitting 
                            ? 'bg-gray-400 cursor-not-allowed' 
                            : submitSuccess 
                              ? 'bg-green-600 hover:bg-green-700' 
                              : 'bg-blue-600 hover:bg-blue-700'
                          }
                        `}
                      >
                        {isSubmitting 
                          ? <span className="flex items-center gap-2">
                              <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                              </svg>
                              {getTranslation('modal.form.submitting')}
                            </span>
                          : submitSuccess 
                            ? getTranslation('modal.form.success')
                            : getTranslation('modal.form.submit')
                        }
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </ModalContext.Provider>
  );
}

export function useModal() {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
}