import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';

export function Footer() {
  const { t } = useLanguage();

  const currentYear = new Date().getFullYear();

  const gridItems = [
    { to: "/services/web-development", label: t('footer.services.web_development'), bgColor: "bg-blue-50 hover:bg-blue-100" },
    { to: "/services/ecommerce-solutions", label: t('footer.services.ecommerce'), bgColor: "bg-indigo-50 hover:bg-indigo-100" },
    { to: "/services/ai-integration", label: t('footer.services.ai_integration'), bgColor: "bg-purple-50 hover:bg-purple-100" },
    { to: "/services/seo-optimization", label: t('footer.services.seo'), bgColor: "bg-pink-50 hover:bg-pink-100" },
    { to: "/services/pwa", label: t('footer.services.pwa'), bgColor: "bg-red-50 hover:bg-red-100" },
    { to: "/services/browser-extensions", label: t('footer.services.browser_extensions'), bgColor: "bg-orange-50 hover:bg-orange-100" },
    { to: "/services/automation-scripts", label: t('footer.services.automation'), bgColor: "bg-yellow-50 hover:bg-yellow-100" },
    { to: "/services/analytics-tracking", label: t('footer.services.analytics'), bgColor: "bg-green-50 hover:bg-green-100" },
    { to: "/services/custom-solutions", label: t('footer.services.custom'), bgColor: "bg-teal-50 hover:bg-teal-100" }
  ];

  return (
    <footer className="bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Services Grid Menu */}
        <div className="mb-16">
          <h3 className="text-lg font-semibold mb-6 text-white text-center">
            {t('footer.sections.services_grid')}
          </h3>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
            {gridItems.map((item, index) => (
              <Link
                key={index}
                to={item.to}
                className={`${item.bgColor} text-gray-800 p-3 rounded-md text-sm text-center transition-colors duration-200 h-12 flex items-center justify-center`}
              >
                {item.label}
              </Link>
            ))}
          </div>
        </div>

        {/* Footer Columns */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Company Info */}
          <div>
            <h3 className="text-lg font-semibold mb-4">
              EcommerceWebServices
            </h3>
            <p className="text-gray-400 mb-4">
              {t('footer.company_info.description')}
            </p>
            <div className="space-y-2 text-gray-400">
              <p>{t('footer.company_info.address')}</p>
              <p>{t('footer.company_info.phone')}</p>
              <p>{t('footer.company_info.email')}</p>
            </div>
          </div>

          {/* Industry Solutions */}
          <div>
            <h3 className="text-lg font-semibold mb-4">
              {t('footer.sections.industry_solutions')}
            </h3>
            <ul className="space-y-2">
              <li>
                <Link to="/industries/retail" className="text-gray-400 hover:text-white">
                  {t('footer.industries.retail')}
                </Link>
              </li>
              <li>
                <Link to="/industries/healthcare" className="text-gray-400 hover:text-white">
                  {t('footer.industries.healthcare')}
                </Link>
              </li>
              <li>
                <Link to="/industries/finance" className="text-gray-400 hover:text-white">
                  {t('footer.industries.finance')}
                </Link>
              </li>
              <li>
                <Link to="/industries/education" className="text-gray-400 hover:text-white">
                  {t('footer.industries.education')}
                </Link>
              </li>
              <li>
                <Link to="/industries/manufacturing" className="text-gray-400 hover:text-white">
                  {t('footer.industries.manufacturing')}
                </Link>
              </li>
              <li>
                <Link to="/industries/real-estate" className="text-gray-400 hover:text-white">
                  {t('footer.industries.real_estate')}
                </Link>
              </li>
              <li>
                <Link to="/industries/travel" className="text-gray-400 hover:text-white">
                  {t('footer.industries.travel')}
                </Link>
              </li>
              <li>
                <Link to="/industries/professionals" className="text-gray-400 hover:text-white">
                  {t('footer.industries.professionals')}
                </Link>
              </li>
              <li>
                <Link to="/industries/smb" className="text-gray-400 hover:text-white">
                  {t('footer.industries.smb')}
                </Link>
              </li>
            </ul>
          </div>

          {/* Services & Resources */}
          <div>
            <h3 className="text-lg font-semibold mb-4">
              {t('footer.sections.services_resources')}
            </h3>
            <ul className="space-y-2">
              <li>
                <Link to="/technical-guides" className="text-gray-400 hover:text-white">
                  {t('footer.resources.guides')}
                </Link>
              </li>
              <li>
                <Link to="/free-tools" className="text-gray-400 hover:text-white">
                  {t('footer.resources.tools')}
                </Link>
              </li>
              <li>
                <Link to="/free-analysis" className="text-gray-400 hover:text-white">
                  {t('footer.resources.analysis')}
                </Link>
              </li>
              <li>
                <Link to="/transformation-roadmaps" className="text-gray-400 hover:text-white">
                  {t('footer.resources.roadmaps')}
                </Link>
              </li>
              <li>
                <Link to="/partners" className="text-gray-400 hover:text-white">
                  {t('footer.resources.partners')}
                </Link>
              </li>
              <li>
                <Link to="/affiliates" className="text-gray-400 hover:text-white">
                  {t('footer.resources.affiliates')}
                </Link>
              </li>
            </ul>
          </div>

          {/* Legal & Policies */}
          <div>
            <h3 className="text-lg font-semibold mb-4">
              {t('footer.sections.legal_policies')}
            </h3>
            <ul className="space-y-2">
              <li>
                <Link to="/legal/terms" className="text-gray-400 hover:text-white">
                  {t('footer.legal.terms')}
                </Link>
              </li>
              <li>
                <Link to="/legal/privacy" className="text-gray-400 hover:text-white">
                  {t('footer.legal.privacy')}
                </Link>
              </li>
              <li>
                <Link to="/legal/cookies" className="text-gray-400 hover:text-white">
                  {t('footer.legal.cookies')}
                </Link>
              </li>
              <li>
                <Link to="/legal/gdpr" className="text-gray-400 hover:text-white">
                  {t('footer.legal.gdpr')}
                </Link>
              </li>
              <li>
                <Link to="/legal/accessibility" className="text-gray-400 hover:text-white">
                  {t('footer.legal.accessibility')}
                </Link>
              </li>
              <li>
                <Link to="/legal/security" className="text-gray-400 hover:text-white">
                  {t('footer.legal.security')}
                </Link>
              </li>
              <li>
                <Link to="/legal/sla" className="text-gray-400 hover:text-white">
                  {t('footer.legal.sla')}
                </Link>
              </li>
              <li>
                <Link to="/legal/impressum" className="text-gray-400 hover:text-white">
                  {t('footer.legal.impressum')}
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-12 pt-8 border-t border-gray-800 text-center text-gray-400">
          <p>{t('footer.copyright') + ` ${currentYear}`}</p>
        </div>
      </div>
    </footer>
  );
}