import React, { useEffect, useState } from 'react';
import { Code2, Menu, X } from 'lucide-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useModal } from '../context/ModalContext';
import { useLanguage } from '../context/LanguageContext';
import { LanguageSelector } from './LanguageSelector';
import { LanguageSwitcher } from './LanguageSwitcher';

// Define a type for the language keys
type LanguageKey = 'en' | 'de' | 'el' | 'tr';

const consultationModalContent = {
  en: {
    title: "Schedule a Consultation",
    description: "Let's discuss your project and find the best solutions for your business needs.",
    form: {
      name: "Full Name",
      email: "Email Address",
      company: "Company Name (Optional)",
      message: "Tell us about your project",
      submit: "Schedule Consultation"
    }
  },
  de: {
    title: "Beratung Vereinbaren",
    description: "Lassen Sie uns Ihr Projekt besprechen und die besten Lösungen für Ihre geschäftlichen Anforderungen finden.",
    form: {
      name: "Vollständiger Name",
      email: "E-Mail-Adresse",
      company: "Firmenname (Optional)",
      message: "Erzählen Sie uns von Ihrem Projekt",
      submit: "Beratung Vereinbaren"
    }
  },
  el: {
    title: "Προγραμματίστε μια Συμβουλευτική",
    description: "Ας συζητήσουμε το έργο σας και ας βρούμε τις καλύτερες λύσεις για τις επιχειρηματικές σας ανάγκες.",
    form: {
      name: "Ονοματεπώνυμο",
      email: "Διεύθυνση Email",
      company: "Όνομα Εταιρείας (Προαιρετικό)",
      message: "Πείτε μας για το έργο σας",
      submit: "Προγραμματισμός Συμβουλευτικής"
    }
  },
  tr: {
    title: "Danışmanlık Planla",
    description: "Projenizi tartışalım ve işletme ihtiyaçlarınız için en iyi çözümleri bulalım.",
    form: {
      name: "Ad Soyad",
      email: "E-posta Adresi",
      company: "Şirket Adı (İsteğe bağlı)",
      message: "Projeniz hakkında bilgi verin",
      submit: "Danışmanlık Planla"
    }
  }
};

export function Header() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const isHomepage = location.pathname === '/';
  const { openModal } = useModal();
  const { t, language } = useLanguage();

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (isHomepage) {
        const heroHeight = document.getElementById('hero')?.offsetHeight || 0;
        setIsVisible(window.scrollY > heroHeight - 80);
      } else {
        setIsVisible(true);
      }
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isHomepage]);

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location]);

  const handleNavigation = (sectionId: string) => {
    const isHomePage = location.pathname === '/';
    const element = document.getElementById(sectionId);

    if (!isHomePage) {
      // If not on homepage, navigate and then scroll
      navigate('/', { state: { scrollTo: sectionId } });
    } else if (element) {
      // If on homepage, scroll directly
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const menuItems = [
    { 
      id: 'services', 
      label: t('header.menu.services'), 
      onClick: () => handleNavigation('services')
    },
    { 
      id: 'portfolio', 
      label: t('header.menu.portfolio'), 
      onClick: () => handleNavigation('portfolio')
    },
    { 
      id: 'testimonials', 
      label: t('header.menu.testimonials'), 
      onClick: () => handleNavigation('testimonials')
    },
    { 
      id: 'contact', 
      label: t('header.menu.contact'), 
      onClick: () => handleNavigation('contact')
    }
  ];

  const handleConsultation = () => {
    openModal({
      title: consultationModalContent[language as LanguageKey].title,
      content: consultationModalContent[language as LanguageKey].description,
      titleKey: 'modal.title',
      contentKey: 'modal.content'
    });
  };

  return (
    <header 
      className={`fixed top-0 left-0 right-0 z-50 bg-white/95 backdrop-blur-sm shadow-md transition-all duration-300 ${
        isVisible ? 'translate-y-0' : isHomepage ? '-translate-y-full' : 'translate-y-0'
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <Link to="/" className="flex items-center gap-2">
            <img className="h-8 w-auto" src="/assets/media/E-CWS-Logo.png" alt="E-CWS Logo" />
            {/* <span className="text-xl font-bold text-gray-900">EcommerceWebServices</span> */}
          </Link>
          
          {/* Add mobile language selector */}
          <div className="flex items-center md:hidden">
            <LanguageSelector />
            <button 
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="ml-2 p-2 rounded-md text-gray-600 hover:text-blue-600 hover:bg-gray-100 transition-colors"
              aria-label="Toggle menu"
            >
              {isMobileMenuOpen ? (
                <X className="w-6 h-6" />
              ) : (
                <Menu className="w-6 h-6" />
              )}
            </button>
          </div>

          {/* Desktop Navigation - remove LanguageSelector from here */}
          <nav className="hidden md:flex items-center gap-6">
            {menuItems.map((item) => (
              <button
                key={item.id}
                onClick={item.onClick}
                className="text-gray-600 hover:text-blue-600 transition-colors"
              >
                {item.label}
              </button>
            ))}
            <LanguageSelector />
            <button 
              onClick={handleConsultation}
              className="px-4 py-2 rounded-md bg-blue-600 text-white hover:bg-blue-700 transition-colors"
            >
              {t('common.schedule_consultation')}
            </button>
          </nav>
        </div>

        {/* Mobile Navigation - remove LanguageSelector from here */}
        <div
          className={`md:hidden transition-all duration-300 ease-in-out ${
            isMobileMenuOpen 
              ? 'max-h-96 opacity-100 visible'
              : 'max-h-0 opacity-0 invisible'
          }`}
        >
          <nav className="py-4 space-y-2 border-t border-gray-200">
            {menuItems.map((item) => (
              <button
                key={item.id}
                onClick={item.onClick}
                className="block px-4 py-2 text-gray-600 hover:text-blue-600 hover:bg-gray-50 rounded-md transition-colors"
              >
                {item.label}
              </button>
            ))}
            <button 
              onClick={handleConsultation}
              className="w-full px-4 py-2 mt-4 rounded-md bg-blue-600 text-white hover:bg-blue-700 transition-colors"
            >
              {t('common.schedule_consultation')}
            </button>
          </nav>
        </div>
      </div>
    </header>
  );
}