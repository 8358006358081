import React, { useState, useEffect } from 'react';
import { X, Check, Settings } from 'lucide-react';
import { useLanguage } from '../context/LanguageContext';
import { useCookieConsent, CookiePreferences } from '../context/CookieConsentContext';
import { CookieConsentHandler } from './CookieConsentHandler';

type LocalCookiePreferences = {
  functional: boolean;
  analytics: boolean;
  marketing: boolean;
  performance: boolean;
};

// Function to get cookie preferences
const getCookiePreferences = (): CookiePreferences => {
  const { cookieConsent } = useCookieConsent();
  return cookieConsent;
};

const content = {
  en: {
    title: "Cookie Settings",
    description: "We use cookies to enhance your browsing experience and analyze our traffic. Please choose your preferences below.",
    necessary: {
      title: "Functional Cookies",
      description: "These cookies are essential for the website to function properly and cannot be disabled."
    },
    analytics: {
      title: "Analytics Cookies",
      description: "Help us understand how visitors interact with our website."
    },
    marketing: {
      title: "Marketing Cookies",
      description: "Used to track visitors across websites to display relevant advertisements."
    },
    performance: {
      title: "Performance Cookies",
      description: "Help us improve our website's performance and user experience."
    },
    buttons: {
      acceptAll: "Accept All",
      acceptSelected: "Accept Selected",
      settings: "Customize",
      save: "Save Preferences"
    }
  },
  de: {
    title: "Cookie-Einstellungen",
    description: "Wir verwenden Cookies, um Ihr Surferlebnis zu verbessern und unseren Verkehr zu analysieren. Bitte wählen Sie unten Ihre Präferenzen.",
    necessary: {
      title: "Funktionale Cookies",
      description: "Diese Cookies sind für das ordnungsgemäße Funktionieren der Website unerlässlich und können nicht deaktiviert werden."
    },
    analytics: {
      title: "Analyse-Cookies",
      description: "Helfen uns zu verstehen, wie Besucher mit unserer Website interagieren."
    },
    marketing: {
      title: "Marketing-Cookies",
      description: "Werden verwendet, um Besucher über Websites hinweg zu verfolgen und relevante Werbung anzuzeigen."
    },
    performance: {
      title: "Performance-Cookies",
      description: "Helfen uns, die Leistung und Benutzerfreundlichkeit unserer Website zu verbessern."
    },
    buttons: {
      acceptAll: "Alle akzeptieren",
      acceptSelected: "Ausgewählte akzeptieren",
      settings: "Anpassen",
      save: "Einstellungen speichern"
    }
  },
  el: {
    title: "Ρυθμίσεις Cookies",
    description: "Χρησιμοποιούμε cookies για να βελτιώσουμε την εμπειρία περιήγησής σας και να αναλύσουμε την κίνησή μας. Παρακαλώ επιλέξτε τις προτιμήσεις σας παρακάτω.",
    necessary: {
      title: "Λειτουργικά Cookies",
      description: "Αυτά τα cookies είναι απαραίτητα για τη σωστή λειτουργία του ιστότοπου και δεν μπορούν να απενεργοποιηθούν."
    },
    analytics: {
      title: "Cookies Ανάλυσης",
      description: "Μας βοηθούν να κατανοήσουμε πώς αλληλεπιδρούν οι επισκέπτες με τον ιστότοπό μας."
    },
    marketing: {
      title: "Cookies Marketing",
      description: "Χρησιμοποιούνται για την παρακολούθηση επισκεπτών σε ιστότοπους για την προβολή σχετικών διαφημίσεων."
    },
    performance: {
      title: "Cookies Απόδοσης",
      description: "Βοηθούν στη βελτίωση της απόδοσης και της εμπειρίας χρήστη του ιστότοπού μας."
    },
    buttons: {
      acceptAll: "Αποδοχή Όλων",
      acceptSelected: "Αποδοχή Επιλεγμένων",
      settings: "Προσαρμογή",
      save: "Αποθήκευση Προτιμήσεων"
    }
  },
  tr: {
    title: "Çerez Ayarları",
    description: "Gezinme deneyiminizi geliştirmek ve trafiğimizi analiz etmek için çerezler kullanıyoruz. Lütfen tercihlerinizi aşağıdan seçin.",
    necessary: {
      title: "İşlevsel Çerezler",
      description: "Bu çerezler, web sitesinin düzgün çalışması için gereklidir ve devre dışı bırakılamaz."
    },
    analytics: {
      title: "Analitik Çerezler",
      description: "Ziyaretçilerin web sitemizle nasıl etkileşimde bulunduğunu anlamamıza yardımcı olur."
    },
    marketing: {
      title: "Pazarlama Çerezleri",
      description: "İlgili reklamları göstermek için ziyaretçileri web siteleri arasında takip etmek için kullanılır."
    },
    performance: {
      title: "Performans Çerezleri",
      description: "Web sitemizin performansını ve kullanıcı deneyimini geliştirmemize yardımcı olur."
    },
    buttons: {
      acceptAll: "Tümünü Kabul Et",
      acceptSelected: "Seçilenleri Kabul Et",
      settings: "Özelleştir",
      save: "Tercihleri Kaydet"
    }
  }
};

// Define the flags array
const flags = [
  { code: 'en', name: 'English', flag: <span>🇬🇧</span> }, // Example flag object
  { code: 'de', name: 'German', flag: <span>🇩🇪</span> },
  { code: 'el', name: 'Greek', flag: <span>🇬🇷</span> },
  { code: 'tr', name: 'Turkish', flag: <span>🇹🇷</span> },
];

export function CookieConsent() {
  const { language, setLanguage } = useLanguage();
  const { cookieConsent, hasConsented, updateCookieConsent } = useCookieConsent();
  const [showSettings, setShowSettings] = useState(false);
  const [preferences, setPreferences] = useState<LocalCookiePreferences>(() => ({
    functional: true,
    analytics: true,
    marketing: true,
    performance: true
  }));

  const selectedContent = content[language as keyof typeof content] || content.en;

  // Update preferences when cookieConsent changes
  useEffect(() => {
    setPreferences({
      functional: cookieConsent.functional,
      analytics: cookieConsent.analytics,
      marketing: cookieConsent.marketing,
      performance: cookieConsent.performance
    });
  }, [cookieConsent]);

  const handleAcceptAll = () => {
    const allEnabled = {
      functional: true,
      analytics: true,
      marketing: true,
      performance: true
    };
    updateCookieConsent(allEnabled);
  };

  const handleSavePreferences = () => {
    updateCookieConsent(preferences);
  };

  return (
    <>
      <CookieConsentHandler />
      {!hasConsented && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold text-gray-900">{selectedContent.title}</h2>
                <div className="flex gap-2">
                  {flags.map((flag) => (
                    <button
                      key={flag.code}
                      onClick={() => setLanguage(flag.code)}
                      className={`p-2 rounded hover:bg-gray-100 ${language === flag.code ? 'bg-gray-100' : ''}`}
                      title={flag.name}
                    >
                      {flag.flag}
                    </button>
                  ))}
                </div>
              </div>

              <p className="text-gray-600 mb-6">{selectedContent.description}</p>

              {showSettings ? (
                <div className="space-y-4">
                  {/* Functional Cookies - Always enabled */}
                  <div className="flex items-center justify-between p-4 bg-gray-50 rounded">
                    <div>
                      <h3 className="font-semibold text-gray-900">{selectedContent.necessary.title}</h3>
                      <p className="text-sm text-gray-600">{selectedContent.necessary.description}</p>
                    </div>
                    <div className="flex items-center">
                      <Check className="w-5 h-5 text-green-600" />
                    </div>
                  </div>

                  {/* Optional Cookies */}
                  {['analytics', 'marketing', 'performance'].map((type) => (
                    <div key={type} className="flex items-center justify-between p-4 bg-gray-50 rounded">
                      <div>
                        <h3 className="font-semibold text-gray-900">
                          {(selectedContent[type as keyof typeof selectedContent] as { title: string }).title}
                        </h3>
                        <p className="text-sm text-gray-600">
                          {(selectedContent[type as keyof typeof selectedContent] as { description: string }).description}
                        </p>
                      </div>
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          checked={preferences[type as keyof LocalCookiePreferences]}
                          onChange={(e) =>
                            setPreferences({ ...preferences, [type]: e.target.checked })
                          }
                        />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                      </label>
                    </div>
                  ))}
                </div>
              ) : null}

              <div className="mt-6 flex flex-wrap gap-3 justify-end">
                {!showSettings ? (
                  <>
                    <button
                      onClick={() => setShowSettings(true)}
                      className="px-4 py-2 text-gray-700 border border-gray-300 rounded hover:bg-gray-50"
                    >
                      <Settings className="w-4 h-4 inline-block mr-2" />
                      {selectedContent.buttons.settings}
                    </button>
                    <button
                      onClick={handleAcceptAll}
                      className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                    >
                      {selectedContent.buttons.acceptAll}
                    </button>
                  </>
                ) : (
                  <button
                    onClick={handleSavePreferences}
                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                  >
                    {selectedContent.buttons.save}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
} 