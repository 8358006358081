import React from 'react';
import ReactDOM from 'react-dom/client';
import { 
  createBrowserRouter, 
  RouterProvider,
  type RouteObject
} from 'react-router-dom';
import App from './App';
import { LanguageProvider } from './context/LanguageContext';
import { CookieConsentProvider } from './context/CookieConsentContext';
import './index.css';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './translations/en.json';
import deTranslations from './translations/de.json';
import elTranslations from './translations/el.json';
import trTranslations from './translations/tr.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslations },
    de: { translation: deTranslations },
    el: { translation: elTranslations },
    tr: { translation: trTranslations }
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
});

// Define router options with correct typing
const routerConfig = {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true,
    v7_fetcherPersist: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true
  }
};

// Create router with proper configuration
const router = createBrowserRouter([
  {
    path: "*",
    element: (
      <LanguageProvider>
        <CookieConsentProvider>
          <App />
        </CookieConsentProvider>
      </LanguageProvider>
    )
  }
], routerConfig);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);