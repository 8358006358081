import { useEffect, useRef } from 'react';
import { useCookieConsent } from '../context/CookieConsentContext';
import { trackingConfig } from '../config/tracking';
import { logger } from '../utils/logger';
import '../types/tracking.d.ts';

export const TrackingScripts = () => {
  const { cookieConsent } = useCookieConsent();
  const pixelInitialized = useRef(false);

  // Facebook Pixel initialization
  useEffect(() => {
    if (!cookieConsent.marketing || pixelInitialized.current) return;

    try {
      // Initialize Facebook Pixel only once
      window.fbq = window.fbq || function() {
        window.fbq.callMethod ? 
          window.fbq.callMethod.apply(window.fbq, arguments) : 
          window.fbq.queue.push(arguments)
      };
      window.fbq.push = window.fbq;
      window.fbq.loaded = true;
      window.fbq.version = '2.0';
      window.fbq.queue = [];

      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://connect.facebook.net/en_US/fbevents.js';
      document.head.appendChild(script);

      window.fbq('init', trackingConfig.facebook.pixelId);
      window.fbq('track', 'PageView');

      pixelInitialized.current = true;
    } catch (error) {
      console.error('Error initializing Facebook Pixel:', error);
    }
  }, [cookieConsent.marketing]);

  // Google Analytics initialization
  useEffect(() => {
    if (!cookieConsent.analytics) return;

    try {
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).gtag = function() {
        (window as any).dataLayer.push(arguments);
      };

      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingConfig.google.analyticsId}`;
      script.onerror = () => console.error('Failed to load Google Analytics');
      document.head.appendChild(script);

      window.gtag('js', new Date());
      window.gtag('config', trackingConfig.google.analyticsId);
    } catch (error) {
      logger.error('Error initializing Google Analytics:', error);
    }
  }, [cookieConsent.analytics]);

  // Other tracking scripts with error handling
  useEffect(() => {
    if (!cookieConsent.marketing) return;

    const loadScript = (src: string, onLoad?: () => void) => {
      try {
        const script = document.createElement('script');
        script.async = true;
        script.src = src;
        if (onLoad) script.onload = onLoad;
        script.onerror = () => console.error(`Failed to load script: ${src}`);
        document.head.appendChild(script);
      } catch (error) {
        console.error(`Error loading script ${src}:`, error);
      }
    };

    // LinkedIn Pixel
    try {
      (window as any)._linkedin_partner_id = trackingConfig.linkedin.pixelId;
      (window as any)._linkedin_data_partner_ids = (window as any)._linkedin_data_partner_ids || [];
      (window as any)._linkedin_data_partner_ids.push((window as any)._linkedin_partner_id);
      loadScript('https://snap.licdn.com/li.lms-analytics/insight.min.js');
    } catch (error) {
      console.error('Error initializing LinkedIn Pixel:', error);
    }

    // Twitter Pixel
    try {
      (window as any).twq = (window as any).twq || function() {
        ((window as any).twq as any).queue.push(arguments);
      };
      ((window as any).twq as any).queue = [];
      loadScript('https://static.ads-twitter.com/uwt.js', () => {
        window.twq('init', trackingConfig.twitter.pixelId);
        window.twq('track', 'PageView');
      });
    } catch (error) {
      console.error('Error initializing Twitter Pixel:', error);
    }
  }, [cookieConsent.marketing]);

  return null;
}; 