import React, { createContext, useContext, useState, useEffect } from 'react';
import en from '../translations/en.json';
import de from '../translations/de.json';
import el from '../translations/el.json';
import tr from '../translations/tr.json';

type Translations = {
  [key: string]: any;
};

const translations: Translations = {
  en,
  de,
  el,
  tr,
};

type LanguageContextType = {
  t: (key: string) => string | string[];
  language: string;
  setLanguage: (lang: string) => void;
};

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

const getInitialLanguage = (): string => {
  const savedLanguage = localStorage.getItem('preferredLanguage');
  if (savedLanguage && translations[savedLanguage]) {
    return savedLanguage;
  }
  
  const browserLang = navigator.language.split('-')[0];
  return translations[browserLang] ? browserLang : 'en';
};

export function LanguageProvider({ children }: { children: React.ReactNode }) {
  const [language, setLanguage] = useState(getInitialLanguage());

  useEffect(() => {
    localStorage.setItem('preferredLanguage', language);
  }, [language]);

  const getNestedValue = (obj: any, path: string): string | string[] => {
    try {
      const value = path.split('.').reduce((current, key) => {
        if (current && typeof current === 'object' && key in current) {
          return current[key];
        }
        throw new Error(`Translation key not found: ${path}`);
      }, obj);

      // Allow both string and array returns
      if (typeof value === 'string' || Array.isArray(value)) {
        return value;
      }
      
      throw new Error(`Translation value is not a string or array: ${path}`);
    } catch (error: any) {
      console.warn(`Translation error: ${error.message}`);
      return path;
    }
  };

  const t = (key: string): string | string[] => {
    if (!key) return '';
    
    const currentTranslations = translations[language];
    if (!currentTranslations) {
      console.warn(`No translations found for language: ${language}`);
      return key;
    }

    try {
      const translation = getNestedValue(currentTranslations, key);
      if (!translation || translation === key) {
        // Fallback to English if translation is missing
        const englishTranslation = getNestedValue(translations.en, key);
        if (englishTranslation && englishTranslation !== key) {
          return englishTranslation;
        }
      }
      return translation;
    } catch (error: any) {
      console.warn(`Translation error for key "${key}":`, error);
      return key;
    }
  };

  const handleSetLanguage = (newLang: string) => {
    if (translations[newLang]) {
      setLanguage(newLang);
    } else {
      console.warn(`Language ${newLang} not supported, falling back to English`);
      setLanguage('en');
    }
  };

  return (
    <LanguageContext.Provider 
      value={{ 
        t, 
        language, 
        setLanguage: handleSetLanguage 
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}