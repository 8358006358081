import React, { createContext, useContext, useState, useEffect } from 'react';

export interface CookiePreferences {
  functional: boolean;
  analytics: boolean;
  marketing: boolean;
  performance: boolean;
}

interface CookieConsentContextType {
  cookieConsent: CookiePreferences;
  hasConsented: boolean;
  updateCookieConsent: (prefs: CookiePreferences) => void;
  resetConsent: () => void;
}

const CookieConsentContext = createContext<CookieConsentContextType | undefined>(undefined);

const DEFAULT_PREFERENCES: CookiePreferences = {
  functional: true,
  analytics: false,
  marketing: false,
  performance: false,
};

export const CookieConsentProvider = ({ children }: { children: React.ReactNode }) => {
  const [cookieConsent, setCookieConsent] = useState<CookiePreferences>(() => {
    try {
      // Try to get from localStorage first
      const saved = localStorage.getItem('cookieConsent');
      if (saved) {
        return JSON.parse(saved);
      }
      
      // If not in localStorage, check sessionStorage
      const session = sessionStorage.getItem('cookieConsent');
      if (session) {
        return JSON.parse(session);
      }
      
      return DEFAULT_PREFERENCES;
    } catch {
      return DEFAULT_PREFERENCES;
    }
  });

  const [hasConsented, setHasConsented] = useState(() => {
    try {
      return Boolean(localStorage.getItem('cookieConsent') || sessionStorage.getItem('cookieConsent'));
    } catch {
      return false;
    }
  });

  const updateCookieConsent = (prefs: CookiePreferences) => {
    setCookieConsent(prefs);
    setHasConsented(true);
    
    try {
      // Store in both storages for persistence
      localStorage.setItem('cookieConsent', JSON.stringify(prefs));
      sessionStorage.setItem('cookieConsent', JSON.stringify(prefs));
      
      window.dispatchEvent(new CustomEvent('cookieConsentUpdated', { detail: prefs }));
    } catch (error) {
      console.error('Error saving cookie consent:', error);
    }
  };

  const resetConsent = () => {
    setHasConsented(false);
    setCookieConsent(DEFAULT_PREFERENCES);
    
    try {
      localStorage.removeItem('cookieConsent');
      sessionStorage.removeItem('cookieConsent');
    } catch (error) {
      console.error('Error resetting cookie consent:', error);
    }
  };

  return (
    <CookieConsentContext.Provider value={{ 
      cookieConsent, 
      hasConsented, 
      updateCookieConsent,
      resetConsent 
    }}>
      {children}
    </CookieConsentContext.Provider>
  );
};

export const useCookieConsent = () => {
  const context = useContext(CookieConsentContext);
  if (!context) {
    throw new Error('useCookieConsent must be used within a CookieConsentProvider');
  }
  return context;
}; 