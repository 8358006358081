import React, { useEffect, useState, useCallback } from 'react';
import { Widget as ReactChatWidget, addResponseMessage } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import { useLanguage } from '../context/LanguageContext';
import { useCookieConsent } from '../context/CookieConsentContext';
import { supabase } from '../lib/supabaseClient';
import { OPENAI_CONFIG } from '../config/openai';

interface TokenUsage {
  sessionId: string;
  tokensUsed: number;
  lastUpdated: string;
}

const TOKEN_LIMIT = OPENAI_CONFIG.SESSION_TOKEN_LIMIT;
const WARNING_THRESHOLD = 0.8;

const content = {
  en: {
    greeting: `👋 Hi there! I'm Teobot, your AI assistant. I can help you with:

• Web Development & Design
• Code Review & Optimization
• SEO & Performance
• Technical Consulting
• Digital Solutions
• Project Planning

What would you like to explore today?`,
    tokenWarning: "You've used 80% of your available tokens. The chat will be limited soon.",
    tokenLimit: "You've reached your token limit for this session.",
    title: "Teobot",
    subtitle: "Ready to assist you!"
  },
  de: {
    greeting: `👋 Hallo! Ich bin Teobot, Ihr KI-Assistent. Ich kann Ihnen helfen bei:

• Webentwicklung & Design
• Code-Review & Optimierung
• SEO & Performance
• Technische Beratung
• Digitale Lösungen
• Projektplanung

Womit kann ich Ihnen heute helfen?`,
    tokenWarning: "Sie haben 80% Ihrer verfügbaren Token verbraucht. Der Chat wird bald eingeschränkt.",
    tokenLimit: "Sie haben Ihr Token-Limit für diese Sitzung erreicht.",
    title: "Teobot",
    subtitle: "Bereit zu helfen!"
  },
  el: {
    greeting: `👋 Γειά σου! Είμαι ο Teobot, ο AI βοηθός σας. Μπορώ να σας βοηθήσω με:

• Ανάπτυξη & Σχεδιασμό Ιστοσελίδων
• Αξιολόγηση & Βελτιστοποίηση Κώδικα
• SEO & Απόδοση
• Τεχνική Συμβουλευτική
• Ψηφιακές Λύσεις
• Σχεδιασμό Έργων

Τι θα θέλατε να εξερευνήσουμε σήμερα;`,
    tokenWarning: "Έχετε χρησιμοποιήσει το 80% των διαθέσιμων token σας. Η συνομιλία θα περιοριστεί σύντομα.",
    tokenLimit: "Έχετε φτάσει το όριο token για αυτή τη συνεδρία.",
    title: "Teobot",
    subtitle: "Έτοιμος να βοηθήσω!"
  },
  tr: {
    greeting: `👋 Merhaba! Ben Teobot, AI asistanınız. Size şu konularda yardımcı olabilirim:

• Web Geliştirme & Tasarım
• Kod İnceleme & Optimizasyon
• SEO & Performans
• Teknik Danışmanlık
• Dijital Çözümler
• Proje Planlama

Bugün hangi konuyu keşfetmek istersiniz?`,
    tokenWarning: "Mevcut token'larınızın %80'ini kullandınız. Sohbet yakında sınırlı olacak.",
    tokenLimit: "Bu oturum için token limitinize ulaştınız.",
    title: "Teobot",
    subtitle: "Yardıma hazırım!"
  }
};

// Define props type for the custom launcher
interface CustomLauncherProps {
  onClick: () => void;
  isOpen: boolean;
}

// Define props type for the launcher wrapper
interface LauncherWrapperProps {
  handleToggle: () => void;
  isOpen: boolean;
}

const CustomLauncher: React.FC<CustomLauncherProps> = ({ onClick, isOpen }) => (
  <button 
    onClick={onClick}
    className="rcw-launcher"
    aria-label="Open chat"
    aria-expanded={isOpen ? "true" : "false"}
    aria-controls="chat-widget"
    aria-haspopup="dialog"
    style={{ cursor: 'pointer' }}
  >
    💬
  </button>
);

const CustomLauncherWrapper: React.FC<LauncherWrapperProps> = ({ handleToggle, isOpen }) => (
  <CustomLauncher onClick={handleToggle} isOpen={isOpen} />
);

interface ChatWidgetProps {
  handleNewUserMessage: (message: string) => void;
  title?: string;
  subtitle?: string;
  customLauncher?: (handleToggle: () => void) => React.ReactNode;
  showCloseButton: boolean;
  autofocus: boolean;
  fullScreenMode: boolean;
}

// Create a wrapper component to handle the defaultProps warning
const WrappedReactChatWidget = (props: any) => {
  const defaultProps = {
    showCloseButton: true,
    autofocus: true,
    fullScreenMode: false,
    ...props
  };

  return <ReactChatWidget {...defaultProps} />;
};

export const AIChatWidget = () => {
  const { language } = useLanguage();
  const { hasConsented } = useCookieConsent();
  const [sessionId] = useState(() => {
    // Try to get existing session ID from storage
    const savedId = localStorage.getItem('chatSessionId');
    if (savedId) return savedId;
    
    // Create new session ID if none exists
    const newId = crypto.randomUUID();
    localStorage.setItem('chatSessionId', newId);
    return newId;
  });
  
  // Add state for chat history
  const [chatHistory, setChatHistory] = useState<Array<{role: string, content: string}>>(() => {
    const saved = localStorage.getItem(`chatHistory_${sessionId}`);
    return saved ? JSON.parse(saved) : [];
  });

  useEffect(() => {
    // Restore chat history when component mounts
    chatHistory.forEach(msg => {
      if (msg.role === 'assistant') {
        addResponseMessage(msg.content);
      }
    });
  }, []);

  // Save chat history when it changes
  useEffect(() => {
    localStorage.setItem(`chatHistory_${sessionId}`, JSON.stringify(chatHistory));
  }, [chatHistory, sessionId]);

  const [tokensUsed, setTokensUsed] = useState(0);
  const [isLimited, setIsLimited] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const selectedContent = content[language as keyof typeof content] || content.en;

  useEffect(() => {
    if (hasConsented && !sessionStorage.getItem('chatGreetingShown')) {
      addResponseMessage(selectedContent.greeting);
      sessionStorage.setItem('chatGreetingShown', 'true');
    }
  }, [hasConsented, language, selectedContent.greeting]);

  // Initialize token tracking
  useEffect(() => {
    const initializeSession = async () => {
      const { data, error } = await supabase
        .from('chat_sessions')
        .insert([
          {
            session_id: sessionId,
            tokens_used: 0,
            created_at: new Date().toISOString()
          }
        ]);

      if (error) console.error('Error initializing chat session:', error);
    };

    if (hasConsented) {
      initializeSession();
    }
  }, [hasConsented, sessionId]);

  const updateTokenUsage = async (newTokens: number) => {
    const updatedTotal = tokensUsed + newTokens;
    setTokensUsed(updatedTotal);

    const { error } = await supabase
      .from('chat_sessions')
      .update({ tokens_used: updatedTotal })
      .eq('session_id', sessionId);

    if (error) console.error('Error updating token usage:', error);

    // Check if we should show warning
    if (updatedTotal >= TOKEN_LIMIT * WARNING_THRESHOLD && !isLimited) {
      addResponseMessage(selectedContent.tokenWarning);
    }

    // Check if we've hit the limit
    if (updatedTotal >= TOKEN_LIMIT) {
      setIsLimited(true);
      addResponseMessage(selectedContent.tokenLimit);
    }
  };

  const handleNewUserMessage = async (newMessage: string) => {
    if (isLimited) {
      addResponseMessage(selectedContent.tokenLimit);
      return;
    }

    setIsLoading(true);
    
    // Update chat history with user message
    setChatHistory(prev => [...prev, { role: 'user', content: newMessage }]);

    try {
      const response = await fetch(OPENAI_CONFIG.API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_CONFIG.API_KEY}`
        },
        body: JSON.stringify({
          model: OPENAI_CONFIG.MODEL,
          messages: [
            { 
              role: "system", 
              content: "You are Teobot, a helpful AI assistant focused on web development and digital solutions." 
            },
            { 
              role: "user", 
              content: newMessage 
            }
          ],
          temperature: OPENAI_CONFIG.TEMPERATURE,
          stream: true,
          max_tokens: OPENAI_CONFIG.MAX_TOKENS
        })
      });

      if (!response.body) throw new Error('No response body');

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let fullMessage = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n').filter(line => line.trim() !== '');

        for (const line of lines) {
          if (line.includes('[DONE]')) continue;
          if (!line.startsWith('data:')) continue;

          const jsonStr = line.replace('data:', '').trim();
          try {
            const json = JSON.parse(jsonStr);
            const content = json.choices[0]?.delta?.content || '';
            fullMessage += content;
          } catch (e) {
            console.error('Error parsing JSON:', e);
          }
        }
      }

      // Update chat history with AI response
      setChatHistory(prev => [...prev, { role: 'assistant', content: fullMessage }]);
      addResponseMessage(fullMessage);

      // Estimate token usage
      const estimatedTokens = Math.ceil((newMessage.length + fullMessage.length) / 4);
      await updateTokenUsage(estimatedTokens);

    } catch (error) {
      console.error('Error in chat:', error);
      addResponseMessage('Sorry, I encountered an error. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Define the handleToggle function
  const handleToggle = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('chatGreetingShown');
    };
  }, []);

  if (!hasConsented) return null;

  return (
    <>
      <div 
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 1000
        }}
      >
        <WrappedReactChatWidget
          handleNewUserMessage={handleNewUserMessage}
          title={selectedContent.title}
          subtitle={selectedContent.subtitle}
          customLauncher={(handleToggle: () => void) => (
            <CustomLauncherWrapper handleToggle={handleToggle} isOpen={isOpen} />
          )}
          handleToggle={handleToggle}
          isOpen={isOpen}
        />
      </div>
      <style>
        {`
          .rcw-widget-container {
            bottom: 0 !important;
            right: 20px !important;
            z-index: 1000 !important;
            width: 400px !important;
          }
          .rcw-conversation-container {
            width: 400px !important;
            height: 500px !important;
            border-radius: 10px !important;
            box-shadow: 0 5px 40px rgba(0, 0, 0, 0.16) !important;
            display: flex !important;
            flex-direction: column !important;
            margin-bottom: 10px !important;
          }
          .rcw-launcher {
            background-color: #4F46E5 !important;
            border: none !important;
            padding: 16px !important;
            border-radius: 50% !important;
            cursor: pointer !important;
            margin: 0 !important;
            transition: transform 0.3s ease !important;
            position: relative !important;
            z-index: 1001 !important;
          }
          .rcw-launcher:hover {
            transform: scale(1.1) !important;
          }
          .rcw-header {
            background-color: #4F46E5 !important;
            padding: 4px 12px !important;
            min-height: 30px !important;
            height: 30px !important;
            display: flex !important;
            align-items: center !important;
            gap: 8px !important;
            border-top-left-radius: 10px !important;
            border-top-right-radius: 10px !important;
            position: relative !important;
          }
          .rcw-title {
            font-size: 14px !important;
            margin: 0 !important;
            line-height: 1 !important;
            color: white !important;
            font-weight: 500 !important;
            flex: 1 !important;
          }
          .rcw-close-button {
            padding: 4px !important;
            height: 20px !important;
            width: 20px !important;
            background: none !important;
            border: none !important;
            cursor: pointer !important;
            opacity: 0.8 !important;
            transition: opacity 0.2s !important;
            position: relative !important;
            z-index: 2 !important;
          }
          .rcw-close-button:hover {
            opacity: 1 !important;
          }
          .rcw-close {
            width: 12px !important;
            height: 12px !important;
          }
          .rcw-header span {
            font-size: 10px !important;
            color: rgba(255, 255, 255, 0.8) !important;
            margin: 0 !important;
            line-height: 1 !important;
            position: absolute !important;
            left: 12px !important;
            bottom: 4px !important;
          }
          .rcw-title {
            margin-top: -8px !important;
            margin-left: 4px !important;
          }
          .rcw-messages-container {
            flex-grow: 1 !important;
            overflow-y: auto !important;
            padding: 8px !important;
            background: white !important;
          }
          .rcw-message {
            max-width: 100% !important;
            padding: 6px 12px !important;
            margin-bottom: 8px !important;
          }
          .rcw-message-text {
            max-width: calc(100% - 40px) !important;
            width: 100% !important;
            white-space: pre-wrap !important;
            background-color: #F3F4F6 !important;
            color: #1F2937 !important;
            padding: 10px !important;
            border-radius: 8px !important;
            font-size: 13px !important;
          }
          .rcw-response {
            width: 100% !important;
            display: flex !important;
            flex-direction: column !important;
          }
          .rcw-timestamp {
            font-size: 10px !important;
            color: #6B7280 !important;
            margin-top: 2px !important;
          }
          .rcw-sender {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            background: white;
            border-top: 1px solid #E5E7EB;
          }
          .rcw-new-message {
            width: 100%;
            max-width: 500px;
            border: 1px solid #E5E7EB;
            border-radius: 20px;
            padding: 8px 16px;
            background: white;
            margin: 0 10px;
          }
          .rcw-send {
            background: #4F46E5;
            color: white;
            border-radius: 50%;
            width: 36px;
            height: 36px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background-color 0.2s;
          }
          .rcw-send:hover {
            background: #4338CA;
          }
          .rcw-picker-btn {
            display: none !important;
          }
          .rcw-sender {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            padding: 12px 16px;
          }
          .rcw-loading {
            display: ${isLoading ? 'flex' : 'none'};
            align-items: center;
            gap: 8px;
            padding: 8px 12px;
            margin: 8px;
            background: rgba(0, 0, 0, 0.05);
            border-radius: 8px;
          }

          .rcw-loading-dot {
            width: 8px;
            height: 8px;
            background: #4F46E5;
            border-radius: 50%;
            animation: bounce 1.4s infinite ease-in-out both;
          }

          .rcw-loading-dot:nth-child(1) { animation-delay: -0.32s; }
          .rcw-loading-dot:nth-child(2) { animation-delay: -0.16s; }

          @keyframes bounce {
            0%, 80%, 100% { transform: scale(0); }
            40% { transform: scale(1); }
          }
        `}
      </style>
      {isLoading && (
        <div className="rcw-loading">
          <div className="rcw-loading-dot"></div>
          <div className="rcw-loading-dot"></div>
          <div className="rcw-loading-dot"></div>
        </div>
      )}
    </>
  );
}; 